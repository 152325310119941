"use client";

import * as React from "react";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";

import { cn } from "@/lib/utils";
import { Icons } from "@/components/brand/icons";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

import { login } from "../api";

export function UserAuthForm({ className, ...props }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const router = useRouter();

  const formSchema = z.object({
    email: z.string().email({
      message: "Invalid email address.",
    }),
    password: z.string().min(8, {
      message: "Password must be at least 8 characters.",
    }),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    ...rest
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function onSubmit(values) {
    try {
      setIsLoading(true);
      await login(values);
      router.push("/");
    } catch (err) {
      setIsLoading(false);
      if (err.response) {
        // Set a general form error
        const message =
          err.response.data.errors[0] || "Not found. Please try again.";
        setError("submit", {
          type: "manual",
          message,
        });
      }
    }
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <Form {...rest} control={control}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-2">
            {errors.submit && (
              <FormDescription className="text-red-500">
                {errors.submit.message}
              </FormDescription>
            )}
            <FormField
              control={control}
              name="email"
              render={({ field }) => (
                <div className="grid gap-1">
                  <FormItem>
                    <FormControl>
                      <Input
                        type="email"
                        placeholder="Email Address"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />

            <FormField
              control={control}
              name="password"
              render={({ field }) => (
                <div className="grid gap-1">
                  <FormItem>
                    <FormControl>
                      <Input
                        type="password"
                        placeholder="Password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />

            <Button disabled={isLoading}>
              {isLoading && (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              )}
              Sign In with Email
            </Button>
          </div>
        </form>
      </Form>
      <div className="flex justify-center -my-3">
        <Link href="/forgot-password" className="text-sm">
          Forgot password?
        </Link>
      </div>
      {/* <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            Don't have an account?
          </span>
        </div>
      </div>
      <Button
        onClick={() => !isLoading && router.push("/signup")}
        variant="ghost"
        type="button"
        disabled={isLoading}
      >
        Create Account
      </Button> */}
    </div>
  );
}
